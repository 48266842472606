/* eslint-disable @typescript-eslint/no-non-null-assertion */
import useMediaQuery from '@mui/material/useMediaQuery';
import { NextRouter, useRouter } from 'next/router';
import { useState } from 'react';

import { theme, palette } from '../../theme';
import { ButtonLink } from '../ButtonLink';
import { Icon } from '../Icon';
import { Logo } from '../Logo';
import { Paragraph } from '../Paragraph';
import text from './MemberTile.constant';
import {
    StyledHeadingContainer,
    StyledHeading,
    StyledBackground,
    StyledButton,
    StyledMembershipType,
    StyledGreeting,
    StyledPinContainer,
    StyledPinWrapper,
    StyledPin,
    HiddenPin,
    StyledDateContainer,
    StyledSubtitle,
    StyledTile,
    StyledParagraph,
    StyledMembershipDescriptionHeading,
    StyledLogoContainer,
} from './MemberTile.styled';
import {
    MemberTileProperties,
    GetStartEndDateTextType,
    GetButtonInformation,
} from './MemberTile.types';
import {
    TggMembershipType,
    TggMemberStatus,
    ProductVariant,
} from '@tgg/common-types';
import {
    CtaButtonClickPayload,
    CtaButtonRenewMembershipPayload,
    EventKey,
    dispatchEvent,
} from '@tgg/services';
import {
    checkFixedTermMembership,
    getFormattedDate,
    getMembershipType,
    MembershipTypeText,
    removeMemberAreaFromUrl,
    truncateText,
    getTestId,
} from '@tgg/util';

const {
    hiText,
    instructionalText,
    membershipText,
    dateTitle: { endDateText, dayPassText, singleDay },
} = text;

const getStartEndDateText = ({
    startDate,
    endDate,
    membershipType,
    memberStatus,
}: Partial<MemberTileProperties>): GetStartEndDateTextType | null => {
    if (memberStatus === TggMemberStatus.Inactive || !startDate || !endDate) {
        return null;
    }

    const formattedStartDate = getFormattedDate(startDate);
    const formattedEndDate = getFormattedDate(endDate);
    if (
        membershipType?.includes(ProductVariant.Student) ||
        checkFixedTermMembership(membershipType!) ||
        memberStatus === TggMemberStatus.Hold
    ) {
        return {
            date: `${formattedEndDate}`,
            title: endDateText,
        };
    }

    if (membershipType?.includes(ProductVariant.DayPass)) {
        const numberOfDays =
            membershipType.split(ProductVariant.DayPass)?.[1] || singleDay;

        const isSingleDay = numberOfDays === singleDay;

        const showDate = isSingleDay
            ? `${formattedEndDate}`
            : `${formattedStartDate} to ${formattedEndDate}`;

        return {
            date: showDate,
            title: `${numberOfDays} ${dayPassText}`,
        };
    }

    return null;
};

const headingsMap = (
    membership: TggMembershipType | string,
    membershipDescription: string | undefined,
) => {
    if (
        (membership === TggMembershipType.Live_It_Fixed &&
            membershipDescription?.toLowerCase().includes('ultimate')) ||
        (/\d+/.test(membership) &&
            membership.toLowerCase().includes('ultimate'))
    ) {
        return 'ULTIMATE';
    }
    if (
        /\d+/.test(membership) &&
        membership.toLowerCase().includes('standard')
    ) {
        return 'STANDARD';
    }
    if (
        /\d+/.test(membership) &&
        membership.toLowerCase().includes('offpeak')
    ) {
        return 'OFF PEAK';
    }
    if (membership.includes(ProductVariant.Student)) {
        return 'STUDENT';
    }
    if (membership.includes(ProductVariant.DayPass)) {
        return 'DAY PASS';
    }
    switch (membership) {
        case TggMembershipType.Staff:
        case 'StaffMembership':
            return 'STAFF';
        case TggMembershipType.Do_It:
        case 'Do_it':
        case 'Complimentary_DO_IT':
            return 'DO IT';

        case TggMembershipType.Live_It:
        case 'Live_It ':
        case 'Live_it':
        case 'Complimentary_LIVE_IT':
        case TggMembershipType.Live_It_Fixed:
            return 'LIVE IT';
        case TggMembershipType.UltimateMonthly:
            return 'ULTIMATE';
        case TggMembershipType.StandardMonthly:
            return 'STANDARD';
        case TggMembershipType.OffPeakMonthly:
            return 'OFF PEAK';
        default:
            return '';
    }
};

const viewYourBenefits = {
    text: 'View your benefits',
    onClick: () => {
        const benefitsElement = document.querySelector('#tgg-member-benefits');
        if (benefitsElement) {
            const benefitsTopPosition =
                benefitsElement.getBoundingClientRect().top +
                window.pageYOffset +
                -60;

            window.scrollTo({ top: benefitsTopPosition, behavior: 'smooth' });
        }
    },
    href: '',
};

const joinNowButton = (hrefPath: string) => ({
    text: 'Join Now',
    href: hrefPath,
    'data-analytics-event': EventKey.CTA_BUTTON_CLICK,
    'data-analytics-payload': JSON.stringify({
        ctaName: 'join now',
        ctaPosition: 'dashboard member tile',
    } as CtaButtonClickPayload),
});

const renewButton = (
    hrefPath: string,
    branchName: string,
    router: NextRouter,
    membershipDescription?: string,
    renewalDaysLeft?: number,
) => ({
    text: 'Renew',
    $isRenewalVariant: true,
    onClick: async () => {
        dispatchEvent<CtaButtonRenewMembershipPayload>(EventKey.CTA_BUTTON, {
            cta_name: 'renew',
            cta_position: 'dashboard member tile',
            current_home_gym: branchName,
            current_membership_product: membershipDescription,
            days_left: renewalDaysLeft,
        });

        await router.push(removeMemberAreaFromUrl(hrefPath));
    },
});

const buttonInformation = (
    memberStatus: TggMemberStatus,
    hrefPath: string,
): GetButtonInformation | null => {
    switch (memberStatus) {
        case TggMemberStatus.Inactive:
            return joinNowButton(hrefPath);
        case TggMemberStatus.Hold:
            return {
                text: 'Unfreeze',
                href: hrefPath,
                'data-testid': `${getTestId('unfreeze-button')}`,
            };
        case TggMemberStatus.Active:
        case TggMemberStatus.Pending:
            return viewYourBenefits;
        default: {
            return null;
        }
    }
};

export const createDurationDescriptor = (
    membershipType: string,
    duration?: number,
) => {
    if (!membershipType || !duration || Number.isNaN(duration)) return '';

    const isDayPass =
        getMembershipType(membershipType) === MembershipTypeText.DAY_PASS;

    const durationDescriptorSuffix = duration !== 1 ? 's' : '';
    return isDayPass
        ? `day${durationDescriptorSuffix}`
        : `month${durationDescriptorSuffix}`;
};

// Function to decide whether and what to display under membership type on right hand side of tile
export const checkMembershipTypeDescription = ({
    membershipType,
    membershipDescription,
    membershipTerm,
}: {
    membershipType: string;
    membershipDescription: string | undefined;
    membershipTerm: number | undefined;
}) => {
    if (
        membershipDescription &&
        getMembershipType(membershipType) === MembershipTypeText.LIVE_IT_FIXED
    ) {
        const duration = membershipDescription.match(/\d+/);
        const durationDescriptor = createDurationDescriptor(
            membershipType,
            Number(duration),
        );

        if (!duration) {
            return {
                showMembershipDescription: false,
                membershipDescriptionText: '',
            };
        }
        return {
            showMembershipDescription: true,
            membershipDescriptionText: `${duration} ${durationDescriptor}`,
        };
        // eslint-disable-next-line no-else-return
    } else if (membershipTerm) {
        const membershipTermDescriptor = createDurationDescriptor(
            membershipType,
            membershipTerm,
        );
        return {
            showMembershipDescription: true,
            membershipDescriptionText: `${membershipTerm} ${membershipTermDescriptor}`,
        };
    }
    return {
        showMembershipDescription: false,
        membershipDescriptionText: '',
    };
};

// This fn checks for pino products and returns either `monthly membership` or `membership`.
// This and the `checkMembershipTypeDescription` function will need to be updated when we introduce
// pino fixed term products or retire legacy products.
const getMembershipText = (membershipType: TggMembershipType | string) =>
    membershipText;

/**
 * MemberTile component is to show case the users membership on the dashboard page
 */
export function MemberTile({
    memberFirstName,
    pin,
    memberStatus,
    membershipType,
    membershipTerm,
    membershipDescription,
    startDate,
    endDate,
    hrefPath,
    isPending9000,
    isEligibleForRenewal,
    branchName,
    renewalDaysLeft,
}: MemberTileProperties) {
    const router = useRouter();
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const firstName = isDesktop
        ? memberFirstName
        : truncateText(memberFirstName, 9);

    const startEndDateText = getStartEndDateText({
        startDate,
        endDate,
        membershipType,
        memberStatus,
    });
    const [showPin, setShowPin] = useState(false);

    const LogoDimensionsProperties = {
        width: isDesktop ? 170 : 120,
        height: isDesktop ? 145 : 110,
        viewBox: isDesktop ? '300 0 100 700' : '300 0 100 740',
    };

    const triggerPin = () => {
        setShowPin(!showPin);
    };

    const isInactiveStatus = memberStatus === TggMemberStatus.Inactive;
    const getMembershipData =
        !isInactiveStatus && !isPending9000
            ? headingsMap(membershipType, membershipDescription)
            : null;
    const getButtonInformation = isPending9000
        ? joinNowButton(hrefPath)
        : isEligibleForRenewal
        ? renewButton(
              hrefPath,
              branchName,
              router,
              membershipDescription,
              renewalDaysLeft,
          )
        : buttonInformation(memberStatus, hrefPath);

    const { showMembershipDescription, membershipDescriptionText } =
        checkMembershipTypeDescription({
            membershipType,
            membershipDescription,
            membershipTerm,
        });

    return (
        <StyledTile>
            <StyledBackground>
                <StyledHeadingContainer>
                    <StyledGreeting>
                        <StyledHeading variant="h1" gutterBottom={false}>
                            {`${hiText}${firstName}`}
                        </StyledHeading>
                        <Paragraph variant="subtitle1" gutterBottom={false}>
                            {instructionalText}
                        </Paragraph>
                    </StyledGreeting>
                    {!isInactiveStatus && getMembershipData && (
                        <StyledMembershipType>
                            <StyledHeading
                                variant="h4"
                                component="h2"
                                gutterBottom={false}
                            >
                                {getMembershipData}
                            </StyledHeading>
                            {showMembershipDescription && (
                                <StyledMembershipDescriptionHeading
                                    component="h3"
                                    gutterBottom={false}
                                    data-test-id="membership-description-heading"
                                >
                                    {membershipDescriptionText}
                                </StyledMembershipDescriptionHeading>
                            )}
                            <StyledParagraph
                                variant="subtitle1"
                                gutterBottom={false}
                            >
                                {getMembershipText(membershipType)}
                            </StyledParagraph>
                        </StyledMembershipType>
                    )}
                </StyledHeadingContainer>
                <StyledPinContainer>
                    <ButtonLink
                        data-testid="membertile-toggleShowPinIcon"
                        onClick={triggerPin}
                    >
                        <Icon name="padlock" color={palette.common.blue} />
                        <StyledPinWrapper data-testid="membertile-pin">
                            {showPin && <StyledPin>{pin}</StyledPin>}
                            {!showPin && (
                                <HiddenPin>
                                    &bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;
                                </HiddenPin>
                            )}
                        </StyledPinWrapper>
                        <Icon
                            name={showPin ? 'pinVisible' : 'pinHidden'}
                            color={palette.common.blue}
                        />
                    </ButtonLink>
                </StyledPinContainer>
                {startEndDateText && (
                    <StyledDateContainer data-testid="membertile-date">
                        <StyledSubtitle
                            variant="subtitle1"
                            gutterBottom={false}
                        >
                            {startEndDateText.title}
                        </StyledSubtitle>
                        <StyledParagraph
                            variant="subtitle1"
                            gutterBottom={false}
                        >
                            {startEndDateText.date}
                        </StyledParagraph>
                    </StyledDateContainer>
                )}
                {getButtonInformation && (
                    <StyledButton
                        size="small"
                        fullWidth={false}
                        {...getButtonInformation}
                    />
                )}

                <StyledLogoContainer>
                    <Logo
                        logoType="positiveNoText"
                        {...LogoDimensionsProperties}
                    />
                </StyledLogoContainer>
            </StyledBackground>
        </StyledTile>
    );
}

export default MemberTile;
