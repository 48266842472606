import useMediaQuery from '@mui/material/useMediaQuery';

import { palette, theme } from '../../theme';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { LinkWithChevron } from '../LinkWithChevron';
import {
    StyledBody,
    StyledIcon,
    StyledContent,
    StyledImage,
    StyledItem,
    StyledIconText,
    StyledLink,
} from './Checklist.styled';
import { ChecklistProperties } from './Checklist.types';

export function Checklist({
    list,
    isColumnFlow,
    isLinkUnderlined = false,
}: ChecklistProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const iconSize = isDesktop ? 34 : 22;
    const imageSize = isDesktop ? 82 : 60;

    return (
        <StyledBody $isColumnFlow={!!isColumnFlow}>
            {list.map(
                ({
                    iconName,
                    customIconCharacter,
                    image,
                    subTitle,
                    description,
                    linkText,
                    linkHref,
                    onTitleClick,
                }) => (
                    <StyledItem
                        $isColumnFlow={!!isColumnFlow}
                        key={`${subTitle}${description}`}
                    >
                        {iconName && (
                            <StyledIcon>
                                <Icon
                                    name={iconName}
                                    color={palette.primary.main}
                                    size={iconSize}
                                />
                            </StyledIcon>
                        )}
                        {customIconCharacter && (
                            <StyledIcon>
                                <StyledIconText>
                                    {customIconCharacter}
                                </StyledIconText>
                            </StyledIcon>
                        )}
                        {image && (
                            <StyledImage
                                height={imageSize}
                                width={imageSize}
                                {...image}
                            />
                        )}
                        <StyledContent $paragraphGutterBottom={!!image}>
                            {subTitle && (
                                <StyledLink
                                    href={linkHref}
                                    $hasLineUnder={isLinkUnderlined}
                                    data-testid="subTitleLink"
                                    onClick={onTitleClick}
                                >
                                    <Heading variant="h4" gutterBottom={false}>
                                        {subTitle}
                                    </Heading>
                                </StyledLink>
                            )}
                            {typeof description === 'string' ? (
                                <p>{description}</p>
                            ) : (
                                description
                            )}
                            {linkText && linkHref && (
                                <LinkWithChevron
                                    href={linkHref}
                                    text={linkText}
                                />
                            )}
                        </StyledContent>
                    </StyledItem>
                ),
            )}
        </StyledBody>
    );
}
