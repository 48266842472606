import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { endOfMonth, addMonths } from 'date-fns';
import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { StyledLabel } from '../ControlledInputs.styled';
import { StyledDateInput } from './ControlledDateInput.styled';
import { ControlledDateInputProperties } from './ControlledDateInput.types';
import { disableSpecificDates } from './utils';

export function ControlledDateInput({
    control,
    placeholder,
    disabledDates = [],
    disabled,
    id,
    label,
    isRequired,
    month1AvailableSlotCount,
    month2AvailableSlotCount,
}: ControlledDateInputProperties) {
    const maxDate = endOfMonth(addMonths(new Date(), 1));

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={id}
                control={control}
                render={({ field }) => (
                    <>
                        {label && (
                            <StyledLabel
                                $hasPaddingTop
                                data-testid={`${id}-label`}
                                htmlFor={id}
                            >
                                {label}
                                {isRequired && <Asterisk spaceBefore />}
                            </StyledLabel>
                        )}

                        <StyledDateInput
                            value={field.value}
                            inputFormat="dd MMM yyyy"
                            onChange={field.onChange}
                            closeOnSelect
                            showToolbar={false}
                            renderInput={parameters => (
                                <TextField
                                    {...parameters}
                                    placeholder={placeholder}
                                    id={id}
                                    fullWidth
                                />
                            )}
                            shouldDisableDate={(date: unknown) =>
                                disableSpecificDates(
                                    date as Date,
                                    disabledDates,
                                    month1AvailableSlotCount,
                                    month2AvailableSlotCount,
                                )
                            }
                            disablePast
                            maxDate={maxDate}
                            disabled={disabled}
                        />
                    </>
                )}
            />
        </LocalizationProvider>
    );
}

export default ControlledDateInput;
