import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { ImageStandalone } from '../ImageStandalone';

export const StyledBody = styled('div')<{ $isColumnFlow: boolean }>`
    display: flex;
    flex-direction: ${({ $isColumnFlow }) =>
        $isColumnFlow ? 'column' : ' row'};
    flex-wrap: wrap;
`;

export const StyledIcon = styled('div')`
    height: 4rem;
    margin-right: ${({ theme }) => theme.spacing(15)};
    padding: ${({ theme }) => theme.spacing(8)};
    border: 0.25rem solid ${palette.grey[900]};
    border-radius: 100%;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        height: 6.2rem;
        padding: ${({ theme }) => theme.spacing(12)};
    }
`;

export const StyledIconText = styled('p')`
    width: 2rem;
    margin: 0;
    padding: 0;
    font-size: 1.9rem;
    font-family: thegymgroupsans-Headline, sans-serif;
    text-align: center;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 3.4rem;
        margin-top: 0.8rem;
        font-size: 2.1rem;
    }
`;

export const StyledLink = styled('a')<{
    $hasLineUnder: boolean;
}>`
    color: ${palette.primary.main};
    text-decoration: ${({ $hasLineUnder }) =>
        $hasLineUnder ? 'underline' : 'none'};
`;

export const StyledImage = styled(ImageStandalone)`
    width: 6rem;
    height: 6rem;
    margin-right: ${({ theme }: { theme: Theme }) => theme.spacing(15)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 8.2rem;
        height: 8.2rem;
    }
`;

export const StyledItem = styled('div')<{ $isColumnFlow: boolean }>`
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    flex-grow: 0;
    width: 100%;
    max-width: 100%;
    padding-bottom: ${({ theme }) => theme.spacing(10)};
    word-break: break-all;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-basis: 50%;
        max-width: ${({ $isColumnFlow }) => ($isColumnFlow ? '100%' : ' 50%')};
        padding-right: ${({ theme }) => theme.spacing(30)};
        padding-bottom: ${({ theme }) => theme.spacing(30)};
    }
`;

export const StyledContent = styled('div')<{
    $paragraphGutterBottom: boolean;
}>`
    p {
        margin-top: 0;
        margin-bottom: ${({ $paragraphGutterBottom }) =>
            $paragraphGutterBottom ? '2rem' : '0'};
        padding-top: ${({ theme }) => theme.spacing(3)};
        padding-bottom: ${({ theme }) => theme.spacing(5)};
        word-break: keep-all;

        b {
            font-family: thegymgroupsans-Headline, sans-serif;
        }

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            padding-top: ${({ theme }) => theme.spacing(8)};
        }
    }
`;
