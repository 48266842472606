import { addMonths, format, isSameMonth } from 'date-fns';

export const formatDate = (date?: Date) => {
    if (!date) return '';

    return format(date, 'yyyy-MM-dd');
};

export const disableSpecificDates = (
    date: Date | null,
    disabledDates: (string | undefined)[],
    month1AvailableSlotCount?: number,
    month2AvailableSlotCount?: number,
) => {
    if (!date) return false;

    const currentMonth = new Date();
    const nextMonth = addMonths(currentMonth, 1);
    // disable current month if there are no available slots
    if (!month1AvailableSlotCount && isSameMonth(date, currentMonth)) {
        return true;
    }
    // disable next month if there are no available slots
    if (!month2AvailableSlotCount && isSameMonth(date, nextMonth)) {
        return true;
    }
    // Drop 2: disable already booked dates
    const formattedDate = formatDate(date);
    return disabledDates.includes(formattedDate);
};
